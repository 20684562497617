<template>
  <header>
    <nav>
      <h1>PokeImcomprendidos</h1>
    </nav>
  </header>
  <h1 v-if="!pokemon">Loading...</h1>
  <div v-else>
    <h1>Quién es este Pokemon?</h1>
    <pokemon-image
      :pokemonId="pokemon.id"
      :showPokemon="showPokemon"
    ></pokemon-image>
    <pokemon-options
      :pokemons="pokemonArr"
      @selectionPokemon="checkAnswer($event)"
    ></pokemon-options>
    <template v-if="showAnswer">
      <h2 class="fade-id">{{ message }}</h2>
      <button @click="newGame">Nuevo Juego</button>
    </template>
  </div>
</template>

<script>
import PokemonImage from "@/components/PokemonImage";
import PokemonOptions from "@/components/PokemonOptions";
import getPokemonOptions from "@/helpers/getPokemonOptions";
export default {
  name: "PokemonPage",
  components: {
    PokemonImage,
    PokemonOptions,
  },
  data() {
    return {
      pokemonArr: [],
      pokemon: null,
      showPokemon: false,
      showAnswer: false,
      message: "",
    };
  },
  methods: {
    async mixPokemonArray() {
      this.pokemonArr = await getPokemonOptions();
      const rndInt = Math.floor(Math.random() * 4);
      this.pokemon = this.pokemonArr[rndInt];
    },
    checkAnswer(selectId) {
      this.showAnswer = true;
      if (this.pokemon.id === selectId) {
        this.showPokemon = true;
        this.message = "Buena infiel 😎. El pokemon es: " + this.pokemon.name;
      } else {
        this.message = "Oe no pe, prueba otro 🤦‍♂️";
      }
    },
    newGame() {
      this.showPokemon = false;
      this.showAnswer = false;
      this.pokemonArr = [];
      this.pokemon = null;
      this.mixPokemonArray();
    },
  },
  mounted() {
    this.mixPokemonArray();
  },
};
</script>

<style scoped>
button {
  padding: 14px 20px;
  background-color: crimson;
  color: beige;
  border: none;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
