<template>
  <div class="options-container">
    <ul>
      <li
        v-for="pokemon in pokemons"
        :key="pokemon.id"
        @click="sendSelection(pokemon.id)"
        :class="classDisable"
      >
        {{ pokemon.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PokemonOptions",
  data() {
    return {
      classDisable: "",
    };
  },
  props: {
    pokemons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sendSelection(pokemonId) {
      this.classDisable = "disable";
      this.$emit("selectionPokemon", pokemonId);
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
li {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 10px;
  width: 250px;
  text-transform: capitalize;
  padding: 10px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

li.disable {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: darkgray;
  color: dimgray;
}

.options-container {
  display: flex;
  justify-content: center;
}
</style>
