import pokemonApi from "@/api/pokemonApi";

const getPokemons = () => {
  const pokemonArr = Array.from(Array(650));
  return pokemonArr.map((_, index) => index + 1);
};

const getPokemonOptions = async () => {
  const mixedPokemons = getPokemons().sort(() => Math.random() - 0.5);
  const pokemons = await getPokemonNames(mixedPokemons.splice(0, 4));
  return pokemons;
};

const getPokemonNames = async ([a, b, c, d] = []) => {
  const arrPromises = [
    pokemonApi.get(`/${a}`),
    pokemonApi.get(`/${b}`),
    pokemonApi.get(`/${c}`),
    pokemonApi.get(`/${d}`),
  ];

  const [pkm1, pkm2, pkm3, pkm4] = await Promise.all(arrPromises);
  return [
    {
      name: pkm1.data.name,
      id: pkm1.data.id,
    },
    {
      name: pkm2.data.name,
      id: pkm2.data.id,
    },
    {
      name: pkm3.data.name,
      id: pkm3.data.id,
    },
    {
      name: pkm4.data.name,
      id: pkm4.data.id,
    },
  ];
};

export default getPokemonOptions;
